<template>
  <main>
    <div class="container">
      <h1>Контакты</h1>
      <hr>
      <h3>Возникли вопросы по технической работе сайта?</h3>
      <p><b>Телефон службы поддержки:</b><br>
      <a href="tel:88007009090">8&nbsp;(800)&nbsp;700-90-90</a></p>
      <p><b>Электронная почта:</b><br>
      <a href="mailto:support@giftery.ru">support@giftery.ru</a></p>
      
      <hr>
      
      <h3>Возникли вопросы по реферальной программе?</h3>
      <p><b>Горячая линия:</b><br>
      <a href="tel:88006001888">8&nbsp;800&nbsp;600&nbsp;1&nbsp;888</a></p>
      <p><b>Электронная почта:</b><br>
      <a href="mailto:social@omoda.ru">social@omoda.ru</a></p>
    </div>
  </main>
</template>

<script>
export default {
}
</script>

<script>
export default {
  name: 'Contacts',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--inner')
  }
}
</script>
