<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Спасибо за участие в реферальной программе O-PEOPLE!</h1>
          <p class="lead">Здесь ты можешь забрать свой подарок.</p>
          <hr>
          <h4 class="colored">Как это работает?</h4>
          <ol>
            <li>Выбери в&nbsp;каталоге одну или несколько подарочных карт</li>
            <li>Укажи номинал подарочных карт из&nbsp;баланса бонусных баллов</li>
            <li>Введи свой адрес <nobr>e-mail</nobr> и&nbsp;получи подарок на&nbsp;почту</li>
          </ol>

          <p>Количество подарочных карт не&nbsp;ограничено и&nbsp;зависит от&nbsp;числа твоих промокодов. После каждого успешного бронирования автомобиля OMODA через мобильное приложение My&nbsp;OMODA на&nbsp;твой бонусный счет будут начисляться баллы, которые можно обменять на&nbsp;электронные подарочные карты.</p>

          <p>* Баллы можно будет использовать в&nbsp;течение <nobr>180-ти</nobr> дней после их&nbsp;начисления на&nbsp;счет.</p>
          <hr>
          <router-link class="button button--rounded button--large" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
          <br>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 60%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 49%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 49%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }
</style>
